<template>
  <div class="hero-text">To jest strona info o {{ var1 }}</div>
</template>

<script>
export default {
  setup() {
    const var1 = 'wartość';
    return {
      var1,
    };
  },
};
</script>

<style scoped>
.hero-text {
  font-size: 22px;
  font-style: italic;
  font-weight: 800;
  color: var(--blue);
}
</style>
